<template>
  <div
      style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center">
    <div style="cursor: none;text-align: center;width: 974px;height: 548px">
      <youtube style="pointer-events: none;cursor: none;object-fit: cover" :video-id="videoId" ref="youtube"
               width="100%" height="100%"
               :player-vars="playerVars"/>
    </div>
    <div style="width: 100%;text-align: center;margin-top: 50px">
      <el-button size="medium" @click="doPlayVideo">{{ showBtnText }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "YoutubeVideo",
  props: {
    videoUrl: {
      type: String,
      default: ''
    },
  },
  beforeDestroy() {
    this.$refs['youtube'].player.clearVideo()
  },
  watch: {
    videoUrl: {
      handler(newVal, oldVal) {
        this.parseVideoUrl()
      },
      immediate: true
    }
  },
  data() {
    return {
      showBtnText: 'PLAY',
      videoId: '',
      playerVars: {
        version: 3,
        autoplay: 1,
        controls: 0,
        rel: 0,
        modestbranding: 0,
        disablekb: 1,
        playsinline: 1,
        showinfo: 0,
        // events: {
        //   'onReady': this.onPlayerReady
        // }
      },
    }
  },
  methods: {
    parseVideoUrl() {
      let url = this.videoUrl
      // https://www.youtube.com/watch?v=y1plpKbdCLg
      this.videoId = url.replace("https://www.youtube.com/watch?v=", "")
    },
    onPlayerReady() {
      console.log(this.$refs['youtube'].player)
      // this.$refs['youtube'].player.videoWidth
    },
    getPlayer(){
      let player = this.$refs['youtube'].player
      return player
    },
    doPlayVideo() {
      if (!this.isPlay) {
        this.$refs['youtube'].player.playVideo()
        this.showBtnText = "PAUSE"
      } else {
        this.$refs['youtube'].player.pauseVideo()
        this.showBtnText = "PLAY"
      }
      this.isPlay = !this.isPlay
    },
  }
}
</script>

<style scoped>

</style>
